// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-post-news-index-js": () => import("./../../../src/templates/post/newsIndex.js" /* webpackChunkName: "component---src-templates-post-news-index-js" */),
  "component---src-templates-post-news-js": () => import("./../../../src/templates/post/news.js" /* webpackChunkName: "component---src-templates-post-news-js" */),
  "component---template-cache-tmp-about-js": () => import("./../../../.template-cache/tmp-about.js" /* webpackChunkName: "component---template-cache-tmp-about-js" */),
  "component---template-cache-tmp-contact-us-js": () => import("./../../../.template-cache/tmp-contact-us.js" /* webpackChunkName: "component---template-cache-tmp-contact-us-js" */),
  "component---template-cache-tmp-cookie-policy-js": () => import("./../../../.template-cache/tmp-cookie-policy.js" /* webpackChunkName: "component---template-cache-tmp-cookie-policy-js" */),
  "component---template-cache-tmp-funds-and-mandates-js": () => import("./../../../.template-cache/tmp-funds-and-mandates.js" /* webpackChunkName: "component---template-cache-tmp-funds-and-mandates-js" */),
  "component---template-cache-tmp-homepage-js": () => import("./../../../.template-cache/tmp-homepage.js" /* webpackChunkName: "component---template-cache-tmp-homepage-js" */),
  "component---template-cache-tmp-portfolio-js": () => import("./../../../.template-cache/tmp-portfolio.js" /* webpackChunkName: "component---template-cache-tmp-portfolio-js" */),
  "component---template-cache-tmp-pradera-management-italy-js": () => import("./../../../.template-cache/tmp-pradera-management-italy.js" /* webpackChunkName: "component---template-cache-tmp-pradera-management-italy-js" */),
  "component---template-cache-tmp-privacy-policy-js": () => import("./../../../.template-cache/tmp-privacy-policy.js" /* webpackChunkName: "component---template-cache-tmp-privacy-policy-js" */),
  "component---template-cache-tmp-sample-page-js": () => import("./../../../.template-cache/tmp-sample-page.js" /* webpackChunkName: "component---template-cache-tmp-sample-page-js" */),
  "component---template-cache-tmp-sustainability-js": () => import("./../../../.template-cache/tmp-sustainability.js" /* webpackChunkName: "component---template-cache-tmp-sustainability-js" */),
  "component---template-cache-tmp-terms-conditions-js": () => import("./../../../.template-cache/tmp-terms-conditions.js" /* webpackChunkName: "component---template-cache-tmp-terms-conditions-js" */)
}

